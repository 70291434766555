import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

import Button from '../Button'
import Icon from '../Icon'
import ContentWrapper from '../ContentWrapper'
import * as s from './style.module.sass'

const NotFound = ({ title, subTitle, content, image, links }) => (
  <ContentWrapper className={s.wrapper}>
    <div className={s.leftBlock}>
      <div>
        <h1 className={s.title}>{title}</h1>
        <div className={s.subTitle}>{subTitle}</div>
        <div className={s.content}>{content}</div>
      </div>
      {links && (
        <div className={s.links}>
          {links.map(({ link, linkText }, index) => (
            <div className={s.link} key={index}>
              <Link to={link}>
                <Icon icon="rightArrow" iconSize="small" containerSize="small" />
                <div className={s.linkText}>{linkText}</div>
              </Link>
            </div>
          ))}
        </div>
      )}
      <div className={s.buttonWrapper}>
        <Link to="/">
          <Button type="outlined">Retour à l'accueil</Button>
        </Link>
      </div>
    </div>
    <div className={`${s.rightBlock} ${s.isDesktop}`}>
      <GatsbyImage image={image?.childImageSharp?.gatsbyImageData} alt="404 image" />
    </div>
    <div
      className={`${s.isTouch} ${s.bgImage}`}
      style={{ backgroundImage: `url(${image?.childImageSharp?.fluid?.src})` }}
    ></div>
  </ContentWrapper>
)

export default NotFound
