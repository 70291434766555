import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import NotFound from '../components/404'

const NotFoundPageTemplate = props => {
  return <NotFound {...props} />
}

NotFoundPageTemplate.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      linkText: PropTypes.string,
    }),
  ),
}

const NotFoundPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { title, subTitle, content, image, links } = frontmatter

  return (
    <Layout>
      <NotFoundPageTemplate title={title} subTitle={subTitle} content={content} image={image} links={links} />
    </Layout>
  )
}

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default NotFoundPage

export const pageQuery = graphql`
  query NotFoundPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "404-page" } }) {
      frontmatter {
        title
        subTitle
        content
        image {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
        }
        links {
          link
          linkText
        }
      }
    }
  }
`
