// extracted by mini-css-extract-plugin
export var wrapper = "style-module--wrapper--990xe";
export var leftBlock = "style-module--leftBlock--SUAJz";
export var rightBlock = "style-module--rightBlock--21tWX";
export var title = "style-module--title--Gyzm9";
export var subTitle = "style-module--subTitle--bLZnI";
export var content = "style-module--content--DNO7o";
export var buttonWrapper = "style-module--buttonWrapper--FHxzu";
export var links = "style-module--links--OD8OZ";
export var link = "style-module--link--CqRql";
export var linkText = "style-module--linkText--EVyY+";
export var bgImage = "style-module--bgImage--3o1bc";
export var isTouch = "style-module--isTouch--oCvQN";
export var isDesktop = "style-module--isDesktop--ep+b1";